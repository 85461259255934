import { useMemo } from 'react';

import { Form, InUse } from '@components';
import { useRouter, PageSection } from '@tripledotstudios/react-core';

import { FloatingLabeledInput, LabeledInput } from '@components/resource';

import { PaymentsRoutes, EventsRoutes } from '@pages/routes';
import { useQuery } from '@hooks';
import { toOptions } from '@services/utils';
import Bots from './form/Bots';
import Ranks from './form/Ranks';

export default function RaceForm({ resource, ...rest }) {
  const { query } = useRouter();

  const { response: gameItems } = useQuery(
    PaymentsRoutes.GameItems.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );

  const gameItemsOptions = useMemo(() => toOptions(
    gameItems?.items || [],
    {
      transform: (option, { backupGameItemsData }) => {
        // eslint-disable-next-line no-param-reassign
        option.backupGameItemsData = backupGameItemsData;

        return option;
      },
    },
  ), [gameItems?.items?.length]);

  const { response: objectiveTemplates } = useQuery(
    EventsRoutes.ObjectiveTemplates.indexRequest,
    { ...query, withoutPagination: true },
  );

  if (!gameItems?.items || !objectiveTemplates?.items) {
    return null;
  }

  const patchedResponse = {
    ...resource,
    data: {
      ...resource.data,
      activityTemplateId: resource.data.activityTemplateId || '',
    },
  };

  return (
    <Form.Resource resource={patchedResponse} {...rest}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" name="name" />
        <InUse.AvailabilityStateFormGroup floating />
      </Form.ControlsLayout>
      <PageSection title="Race configuration">
        <LabeledInput label="Player speed reference" name="playerSpeedRef" type="number" />
        <LabeledInput label="Target" name="target" type="number" />
      </PageSection>
      <Bots max={resource.data.maxBots} />
      <Ranks gameItemsOptions={gameItemsOptions} />
    </Form.Resource>
  );
}
